import { alphaColorReplacer } from '@semios/app-platform-banyan-app/dist/settings/colors'
import type { TContainerProps } from 'App/components/Map/components/Panels/utils/types'
import { usePanelPositions } from 'App/components/Map/components/Panels/utils/usePanelPositions'
import { MapControls } from 'App/components/Map/MapControls/MapControls'
import { colors } from 'settings/colors'

export const PanelMapControls = (props: TContainerProps) => {
  const panelPositions = usePanelPositions(props)

  return (
    <div
      css={{
        position: 'absolute',
        top: panelPositions.mapControls.top,
        right: panelPositions.mapControls.right,
        width: panelPositions.mapControls.width,
        height: panelPositions.mapControls.height,
        backgroundColor: alphaColorReplacer(colors.midnight, 0.6),
        color: colors.white,
        padding: 12,
        borderRadius: 3,
      }}
    >
      <MapControls />
    </div>
  )
}
