import { SignUp } from 'App/components/SignUp/SignUp'
import { translate } from 'i18n/i18n'
import { useEffect } from 'react'
import { globalStore } from 'stores/globalStore'
import { getUserUnits } from 'stores/utils/getUserUnits'
import { useApiCurrentValues } from 'utils/useApiCurrentValues'
import { useSlowlyRenderText } from 'utils/useSlowlyRenderText'
import { SummaryStatementWrapper } from '../SummaryStatementWrapper/SummaryStatementWrapper'
import type { RegionValues, TSidekickDegreeDaysStatement, TSidekickTrapsStatement } from './types'
import { GeneralDisclaimer } from '../GeneralDisclaimer/GeneralDisclaimer'

const valuesToRequest: Partial<Record<keyof RegionValues, true>> = {
  trap_catches_insect_id_1_sidekick: true,
  trap_catches_insect_id_6_sidekick: true,
  degree_days_insect_id_1_sidekick: true,
  degree_days_insect_id_6_sidekick: true,
}

const getPreferredInsectId = (regionName: string): 1 | 6 => {
  const regex = /, CA$/

  if (regex.test(regionName)) {
    return 6
  }

  return 1
}

const getTrapStatement = (trapStatement: TSidekickTrapsStatement): string | null => {
  if (!trapStatement) return null

  return trapStatement.value.statements.statement
}

const getDegreeDayStatement = (degreeDayStatement: TSidekickDegreeDaysStatement): string | null => {
  if (!degreeDayStatement) return null

  const userUnits = getUserUnits()

  if (userUnits === 'IMPERIAL') {
    return degreeDayStatement.value.statements.imperialStatement
  }

  return degreeDayStatement.value.statements.metricStatement
}

export const PestCard = ({
  setPanelTitle,
}: {
  setPanelTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>
}) => {
  const selectedRegion = globalStore.useSelector((state) => state.selectedRegion)

  useEffect(() => {
    if (selectedRegion) setPanelTitle(selectedRegion.name)
  }, [selectedRegion?.name])

  const { data } = useApiCurrentValues({
    args: {
      regions: {
        regionIds: selectedRegion ? [selectedRegion.id] : [],
        values: valuesToRequest,
      },
    },
  })

  const regionData = selectedRegion ? data?.regions?.[selectedRegion.id] ?? {} : {}
  const trapStatements: Record<number, TSidekickTrapsStatement> = {}
  const degreeDayStatements: Record<number, TSidekickDegreeDaysStatement> = {}

  Object.keys(regionData).forEach((key) => {
    if (key in valuesToRequest) {
      const castKey = key as keyof RegionValues

      const id = parseInt(
        key
          .replace('_sidekick', '')
          .replace('trap_catches_insect_id_', '')
          .replace('degree_days_insect_id_', ''),
      )

      if (key.startsWith('trap_catches')) {
        trapStatements[id] = regionData[castKey] as TSidekickTrapsStatement
      } else if (key.startsWith('degree_days')) {
        degreeDayStatements[id] = regionData[castKey] as TSidekickDegreeDaysStatement
      }
    }
  })

  const insectIdToUse = getPreferredInsectId(selectedRegion?.name ?? '')
  const rawDegreeDaySummaryText = getDegreeDayStatement(degreeDayStatements[insectIdToUse] ?? null)
  const degreeDaySummaryText = useSlowlyRenderText(rawDegreeDaySummaryText ?? '')
  const rawTrapSummaryText = getTrapStatement(trapStatements[insectIdToUse] ?? null)
  const trapSummaryText = useSlowlyRenderText(rawTrapSummaryText ?? '')

  const pestName =
    insectIdToUse === 1
      ? translate.phrases.landingPage('Codling Moth')
      : translate.phrases.landingPage('Navel Orangeworm')

  return (
    <div css={{ '& > *': { margin: 10 } }}>
      <div css={{ textAlign: 'center', fontSize: 18, paddingBottom: 20 }}>{pestName}</div>
      {rawDegreeDaySummaryText && (
        <SummaryStatementWrapper key={`DD_${insectIdToUse}`}>
          <div>{degreeDaySummaryText.textRenderedSoFar}</div>
        </SummaryStatementWrapper>
      )}
      {rawTrapSummaryText && (
        <SummaryStatementWrapper key={`TRAP_${insectIdToUse}`}>
          <div>{trapSummaryText.textRenderedSoFar}</div>
        </SummaryStatementWrapper>
      )}
      {!rawDegreeDaySummaryText && !rawTrapSummaryText && (
        <div>
          {translate.phrases.placeholder(
            '{{pestName}} data isn’t available for this region right now. Try exploring a different location instead.',
            { pestName },
          )}
        </div>
      )}
      {(degreeDaySummaryText.isFullyRendered || !rawDegreeDaySummaryText) &&
        (trapSummaryText.isFullyRendered || !rawTrapSummaryText) && (
          <>
            <SignUp ctaText={translate.phrases.placeholder('Create an account to unlock more insights')} />
            <GeneralDisclaimer />
          </>
        )}
    </div>
  )
}
