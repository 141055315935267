import { SignUp } from 'App/components/SignUp/SignUp'
import { translate } from 'i18n/i18n'
import { useEffect, useMemo } from 'react'
import { globalStore } from 'stores/globalStore'
import { useApiCurrentValues } from 'utils/useApiCurrentValues'
import { useSlowlyRenderText } from 'utils/useSlowlyRenderText'
import { SummaryStatementWrapper } from '../SummaryStatementWrapper/SummaryStatementWrapper'
import { CurrentWeather } from './components/CurrentWeather/CurrentWeather'
import { WeatherForecast } from './components/WeatherForecast/WeatherForecast'
import type { TStatementGeneric } from './types'
import { getWeatherStatements } from './utils/getWeatherStatements'
import { GeneralDisclaimer } from '../GeneralDisclaimer/GeneralDisclaimer'

const isKeyRenderable = (key: string): key is keyof typeof getWeatherStatements => {
  return key in getWeatherStatements
}

export const WeatherCard = ({
  setPanelTitle,
}: {
  setPanelTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>
}) => {
  const selectedRegion = globalStore.useSelector((state) => state.selectedRegion)

  useEffect(() => {
    if (selectedRegion) setPanelTitle(selectedRegion.name)
  }, [selectedRegion?.name])

  const { data, loading } = useApiCurrentValues({
    args: {
      regions: {
        regionIds: selectedRegion ? [selectedRegion.id] : [],
        values: {
          air_temperature_sidekick: true,
          weatherForecastDaily: true,
          weatherIcon: true,
          temperature_minMedianMax_in_c: true,
        },
      },
    },
    preventFetch: !selectedRegion,
  })

  const regionData = selectedRegion ? data?.regions?.[selectedRegion.id] : null

  const statementText = useMemo(() => {
    let statementText: string | null = null

    for (const key in regionData) {
      if (isKeyRenderable(key)) {
        if (key === 'air_temperature_sidekick') {
          statementText = getWeatherStatements[key](regionData[key] as TStatementGeneric<typeof key>)
        }
      }
    }

    return statementText
  }, [regionData])

  const summaryText = useSlowlyRenderText(statementText ?? '')

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        minHeight: 400,
      }}
    >
      {!selectedRegion || loading ? (
        <></>
      ) : (
        <>
          <CurrentWeather
            weatherForecastDaily={regionData?.weatherForecastDaily}
            iconCode={regionData?.weatherIcon}
            currentTemperature={regionData?.temperature_minMedianMax_in_c}
          />
          {!!summaryText.completeTextString && (
            <div>
              <SummaryStatementWrapper>{summaryText.textRenderedSoFar}</SummaryStatementWrapper>
            </div>
          )}
          {!!summaryText.completeTextString && summaryText.isFullyRendered && (
            <>
              <WeatherForecast dailyWeatherData={regionData?.weatherForecastDaily} />
              <SignUp ctaText={translate.phrases.placeholder('Create an account to unlock more insights')} />
            </>
          )}
          {summaryText.isFullyRendered && <GeneralDisclaimer />}
        </>
      )}
    </div>
  )
}
